<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :width="800"
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="批量输入"
      @ok="handleSubmit"
    >
      <a-row>
        <a-form
          class="iotplt-compact-form"
          :form="form"
          @submit="handleSubmit"
        >
          <a-col :span="24">
            <a-form-item
              :wrapper-col="{ span: 24 }"
            >
              <common-iccid-input
                :rows="15"
                ref="iccidInput"
                v-decorator="['iccids', {
                  rules: [{ validator: checkIccids }],
                }]"
              />
            </a-form-item>
          </a-col>
        </a-form>
      </a-row>
    </a-modal>
  </div>
</template>

<script>
import CommonIccidInput from '@/components/IccidInput/Common'
import { batchValidateSearchAgentSimCards } from '@/api/sim_card'

export default {
  name: 'BatchInputIccids',
  components: { CommonIccidInput },
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    agentId: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'batch_input_iccids' }),
      submitting: false
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    checkIccids(rule, value, callback) {
      if (!value || !value.inputValue) {
        // eslint-disable-next-line standard/no-callback-literal
        callback('请输入ICCID')
        return
      }

      if (value.validateStatus === 'error') {
        callback(value.errorMsg)
        return
      }

      // eslint-disable-next-line standard/no-callback-literal
      callback()
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values,
            iccids: values.iccids.data,
            agent_id: this.agentId
          }
          batchValidateSearchAgentSimCards(data).then((res) => {
            if (res.code === 0) {
              if (res.data.is_valid) {
                // 关闭模态框
                this.isShow = false
                this.$emit('submit', values.iccids.data.join(','))
              } else {
                this.form.setFieldsValue({
                  iccids: { inputValue: res.data.result }
                })

                this.$nextTick(() => {
                  this.$refs.iccidInput.handleIccids()
                })
              }
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
